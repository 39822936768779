<template>
  <div class="status-c">
    <fm-title title-text="状态切换" :title-menus="[{key: 'add', label: '新增'}]" @clickTitleMenu="clickTitleMenu">
    </fm-title>
    <div class="c-table">
      <fm-table-new
        :columns="columns"
        :auto-height="true"
        ref="table"
        :data-list="switchList"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      </fm-table-new>
    </div>
    <fm-modal v-model="openCondition" width="800" v-if="openCondition">
      <div style="width: 100%;height: 70vh">
      <condition
        :data-source-keys="dataSourceKeys"
        :action-label="chooseData.statusLabel + ' ' + chooseData.label + ' ' + chooseData.newStatusLabel"
        :user-source-keys="userSourceKeys"
        @dataUpdate="$emit('dataUpdate')"
        action-type="status_switch"
        :action-id="chooseData.id"
        :condition-list="chooseData.conditionList"></condition>
      </div>
    </fm-modal>
    <fm-form-dialog
      form-title="状态切换"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import Condition from './condition'

import {
  statusManageRequest
} from '@/api'

export default {
  components: {
    TableActions,
    Condition
  },
  props: {
    statusGroupId: { type: Number },
    statusList: { type: Array},
    switchList: { type: Array},
    dataSourceKeys: { type: Array},
    userSourceKeys: { type: Array}
  },
  data () {
    return {
      chooseData: {},
      openCondition: false,
      openDialog: false
    }
  },
  watch: {
    switchList: {
      deep: true,
      handler () {
        let data = this.chooseData && this.chooseData.id ? this.switchList.find(v => v.id === this.chooseData.id) : null
        this.chooseData = data ? Object.assign({}, data, {
          isHost: data.isHost ? '1' : '0'
        }) : {}
      },
      immediate: true
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '原状态',
        key: 'statusId',
        selectDatas: this.statusList.map(v => {
          return {
            key: v.id,
            label: v.label
          }
        }),
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '新状态',
        key: 'newStatusId',
        selectDatas: this.statusList.map(v => {
          return {
            key: v.id,
            label: v.label
          }
        }),
        check: {
          required: true
        }
      },
      {
        type: 'input',
        label: '动作',
        key: 'label',
        check: {
          required: true
        }
      },
      {
        type: 'input',
        label: '顺序',
        key: 'sortNum',
        check: {
          required: true,
          rules: {
            type: 'number'
          }
        }
      },
      {
        type: 'input',
        label: '维护字段',
        key: 'updateKeys'
      },
      {
        type: 'input',
        label: '标签',
        key: 'tag'
      },
      {
        type: 'select',
        label: '是否主流程',
        key: 'isHost',
        selectDatas: [{key: '1', label: '是'}, {key: '0', label: '否'}],
        check: {
          required: true
        }
      }]
    },
    tableActions () {
      return [{
        key: 'condition',
        label: '条件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columns () {
      return [{
        field: 'statusLabel',
        title: '原状态'
      },
      {
        field: 'label',
        title: '动作'
      },
      {
        field: 'newStatusLabel',
        title: '新状态'
      },
      {
        field: 'sortNum',
        title: '顺序'
      },
      {
        field: 'updateKeys',
        title: '维护字段'
      },
      {
        field: 'tag',
        title: '标签'
      },
      {
        field: 'isHost',
        title: '是否主流程',
        render: (h, rowData) => {
          return h('div', rowData.isHost ? '是' : '-')
        }
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions',
        width: 80,
        fixed: 'right',
        search: false,
        export: false
      }]
    }
  },
  methods: {
    async formSubmit (data, resolve) {
      if (data.id) {
        await statusManageRequest.addSwitch(data)
      } else {
        data.statusGroupId = this.statusGroupId
        await statusManageRequest.addSwitch(data)
      }
      this.openDialog = false
      this.$emit('dataUpdate')
      resolve()
    },
    async tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data, {
        isHost: parm.data.isHost ? '1' : '0'
      })
      if (parm.action === 'condition') {
        this.openCondition = true
      } else if (parm.action === 'edit') {
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该状态切换吗?'})
        if (result) {
          await statusManageRequest.delSwitch(this.chooseData.id)
          this.$emit('dataUpdate')
        }
      }
    },
    clickTitleMenu () {
      this.chooseData = {}
      this.openDialog = true
    }
  }
}
</script>

<style scoped lang="less">
.status-c {
  width: 100%;
  height: 100%;
}
.c-table {
  width: 100%;
  height: calc(100% - 48px);
}
</style>